import React, { useState, useEffect, useRef } from "react"
import Layout from "../layout"
import SEO from "../seo"
import { Link, navigate } from "gatsby"
import Modal from "../modal"
import RegisterForm from "../register-form"
import {
  lang,
  lng,
  setLang,
  setEventScrollPosition,
  eventScrollPosition,
  setBackPage,
} from "../essentials"
import { window, document } from "browser-monads"
import MarkdownViewer from "../MarkdownViewer"

export default function EventPage(props) {
  const event = props.pageContext.event
  setLang(props.pageContext.lang)

  const prefix = lng("", "/ar")

  setBackPage(window.location.pathname)

  let [showState, setShowState] = useState(false)
  let [form_data, setFormData] = useState({})

  let i = 0

  const divRef = useRef(null)

  let markdownText = lng(event.details, event.arabic_details)

  // Replacing sections
  let oldMarkDown = []
  markdownText.split("[[").map((chunk, index) => {
    if (index > 0) {
      chunk = chunk.split("]]")
      chunk[0] = <div id={`section-${chunk[0]}`}></div>
    }
    oldMarkDown = oldMarkDown.concat(chunk)
  })

  markdownText = oldMarkDown

  // Replacing buttons
  let newMarkDown = []
  markdownText.map(ch => {
    if (typeof ch == "string") {
      let newCh = []
      ch = ch.split("{{").map((chunk, index) => {
        if (index > 0) {
          chunk = chunk.split("}}")
          const text = chunk[0]
          let formURL = `${lang == "ar" ? "/ar" : ""}${
            event.formUrl || `/forms/${chunk[0]}`
          }`

          // Detailed agenda page
          if (text === "view-detailed-agenda")
            formURL = `${lng(
              "",
              "/ar"
            )}/annual-emm-science-workshop/agenda-day2`

          chunk[0] = (
            <button
              class="uk-button uk-button-default"
              onClick={() => window.open(formURL)}
              style={event.button?.style || {}}
            >
              {lng(
                text || event.button?.text || "Register",
                text || event.button?.arabic_text || "سجل من هنا"
              )}
            </button>
          )
        }
        newCh = newCh.concat(chunk)
      })
      ch = newCh
    }
    newMarkDown = newMarkDown.concat(ch)
  })

  useEffect(() => {
    setTimeout(() => {
      const ele = document.getElementById("section-" + eventScrollPosition)
      if (ele) {
        const offset = ele.getBoundingClientRect().top - 100
        window.scrollTo(0, offset)
      }
      setEventScrollPosition("null")
    }, 100)
  }, [])

  return (
    <Layout lang={lang}>
      <SEO title={event.name} lang={lang} />
      <main style={{ paddingTop: "12rem" }}>
        <div
          className="standard-container"
          style={{ paddingTop: "6rem", paddingBottom: "6rem" }}
          {...(event.noAr ? { dir: "ltr" } : {})}
        >
          <div
            {...(!event.thumbnail
              ? {}
              : {
                  className: "event-page-grid-container",
                  style: { gridGap: "30px" },
                })}
          >
            <div
              className="flex items-center"
              style={{ flexDirection: "column", position: "relative" }}
            >
              <h2 class="relative uk-text-center mb4-ns">
                {lng(event.name, event.arabic_name)}
                <div
                  class="text-underline-heavy"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                ></div>
              </h2>
              {event.thumbnail && (
                <img
                  src={event.thumbnail.publicURL}
                  style={{
                    maxWidth: "300px",
                    maxHeight: "300px",
                  }}
                />
              )}
              {!event.hide?.back && (
                <div className="relative mv4 w-100">
                  <span
                    className={`event-page-back ${
                      lang == "ar" ? "uk-position-right" : "uk-position-left"
                    }`}
                    style={{ color: "#fff" }}
                  >
                    <Link to={prefix + "/gen-hope/programmes"}>
                      <span style={{ color: "#fff" }}>
                        <b>{lng("BACK", "عودة")}</b>
                      </span>
                    </Link>
                  </span>
                </div>
              )}
            </div>
            <div
              className="flex items-start justify-center markdown-container"
              style={{ flexDirection: "column" }}
            >
              {event[lng("details", "arabic_details")] &&
              event[lng("details", "arabic_details")] != ""
                ? newMarkDown.map(chunk =>
                    typeof chunk == "string" ? (
                      <MarkdownViewer>{chunk}</MarkdownViewer>
                    ) : (
                      chunk
                    )
                  )
                : ""}
            </div>
          </div>
        </div>
        <Modal
          visible={showState}
          closeFunction={() => {
            setShowState(false)
          }}
        >
          {JSON.stringify(form_data) != "{}" ? (
            <RegisterForm
              form_data={form_data}
              lang={lang}
              closeFunction={() => {
                setShowState(false)
              }}
            />
          ) : (
            ""
          )}
        </Modal>
      </main>
    </Layout>
  )
}
