import React, { useEffect, useRef } from "react"
import ReactMarkdown from "react-markdown"

export default function MarkdownViewer(props) {
  const containerRef = useRef()

  useEffect(() => {
    const container = containerRef.current
    const paras = container.getElementsByTagName("p")
    for (const para of paras)
      para.innerHTML = para.innerHTML.split("\n").join("<br />")
  })

  return (
    <div ref={containerRef}>
      <ReactMarkdown>{props.children}</ReactMarkdown>
    </div>
  )
}
