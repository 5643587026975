import React from 'react';

export default function Modal(props) {
    return (
        <div style={{ 
            position: 'fixed', 
            zIndex: '1000', 
            height: '100vh', 
            width: '100vw', 
            background: 'rgba(0,0,0,0.7)', 
            top: '0', 
            left: '0',
            display: props.visible ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{ background: '#222', padding: '40px', width: 'calc(90vw - 40px)', maxWidth: '800px', maxHeight: 'calc(90vh - 80px)', overflow: 'auto', boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)' }}>
                {props.children}
            </div>
            <div style={{ position: 'absolute', zIndex: '110', top: '20px', right: '20px' }}>
                <button onClick={props.closeFunction} className="uk-button uk-button-default" style={{ padding: '0', textTransform: 'uppercase', height: '40px', width: '40px', borderRadius: '20px' }}>X</button>
            </div>
        </div>
    )
}